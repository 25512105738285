import React, {useEffect, useState} from 'react';
import {Card, Input, Space, Image, Pagination, Button, Modal} from 'antd';
import './index.css';
import {CaretRightOutlined, ArrowRightOutlined, SettingOutlined} from '@ant-design/icons';
import {
    useNavigate
} from "react-router-dom";
import {Col, Container, Row} from 'reactstrap';
import {useParams} from "react-router";
import {chargerListType, chargeSocketListType} from "../../../types/admin";
import axios from "axios";
import {PopNotification} from "../../../shared/notification";
import {useCredentialStore} from "../../../hooks/useCredentialsStore";

const SingleChargePoint: React.FC = ({children}) => {

    const {getGroups, token} = useCredentialStore(({getGroups, token}) => ({
        getGroups,
        token
    }));

    const [isRestartModalVisible, setIsRestartModalVisible] = useState(false);

    const queryParams = new URLSearchParams(window.location.search)
    const charger_id = queryParams.get("chargerId")
    const city_name = queryParams.get("cityName")
    const unique_id = queryParams.get("uniqueId")

    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(0);
    const [chargerSocketDetails, setChargerSocketDetails] = useState<chargeSocketListType>();
    const [textSearch, setTextSearch] = useState('');

    const onSearch = (value: string) => setTextSearch(value);
    const navigate = useNavigate();

    const getChargerList = () => {
        axios
            .get(`/api/charger/${charger_id}/socketsWithStatus?pageSize=${pageSize}&page=${page}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then((res) => {
                setChargerSocketDetails(res.data)
                // PopNotification("success", "Charge Point Created Successfully");
            })
            .catch((error) => {
                PopNotification("error", error || error?.response?.data?.message || `Something went wrong, ${error.toString()}`);
            })
            .finally(() => {
                // setLoading(false);
            });
    }

    const onStartSocket = (socket_id: string) => {
        axios
            .post(`/api/socket/${socket_id}/charge`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then((res) => {
                console.log(res.data);
                PopNotification("success", "Operation Success");
                getChargerList();
            })
            .catch((error) => {
                PopNotification("error", error || error?.response?.data?.message || `Something went wrong, ${error.toString()}`);
            })
            .finally(() => {
                // setLoading(false);
            });
    }

    const onStopSocket = (socket_id: string) => {
        axios
            .post(`/api/socket/${socket_id}/stopCharge`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then((res) => {
                console.log(res.data);
                PopNotification("success", "Operation Success");
                getChargerList();
            })
            .catch((error) => {
                PopNotification("error", error || error?.response?.data?.message || `Something went wrong, ${error.toString()}`);
            })
            .finally(() => {
                // setLoading(false);
            });
    }

    const onUnlockSocketCable = (socket_id: string) => {
        axios
            .post(`/api/socket/${socket_id}/unlockCable`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then((res) => {
                console.log(res.data);
                PopNotification("success", "Operation Success");
                getChargerList();
            })
            .catch((error) => {
                PopNotification("error", error || error?.response?.data?.message || `Something went wrong, ${error.toString()}`);
            })
            .finally(() => {
                // setLoading(false);
            });
    }

    const retestChargers = () => {
        axios.post(`/api/charger/${charger_id}/restart`, {}, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then((res) => {
                PopNotification("success", "Restart Successful. Please wait for few seconds.");
            })
            .catch((error) => {
                PopNotification("error", error || error?.response?.data?.message || `Something went wrong, ${error.toString()}`);
            })
            .finally(() => {
                setIsRestartModalVisible(false);
                window.location.reload()
            });
    }

    const onUpBarrier = (socket_id: string) => {
        axios.post(`/api/carBarrier/command`, {
            socketId: socket_id,
            command: "UP"
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then((res) => {
                PopNotification("success", "Barrier Up Command Sent. Please wait for few seconds.");
            })
            .catch((error) => {
                PopNotification("error", error || error?.response?.data?.message || `Something went wrong, ${error.toString()}`);
            })
    }

    const onDownBarrier = (socket_id: string) => {
        axios.post(`/api/carBarrier/command`, {
            socketId: socket_id,
            command: "DOWN"
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then((res) => {
                PopNotification("success", "Barrier Down Command Sent. Please wait for few seconds.");
            })
            .catch((error) => {
                PopNotification("error", error || error?.response?.data?.message || `Something went wrong, ${error.toString()}`);
            })
    }

    useEffect(() => {
        getChargerList();
    }, [page, pageSize]);

    return (
        <div>
            <span className='page-title'> {unique_id} | {city_name}</span>
            <Container fluid={true} style={{paddingLeft: 0, paddingRight: 0}}>
                <Row>
                    <Col md={4}>
                        <div style={{width: "50%"}}>
                            <Button block type="primary" onClick={() => setIsRestartModalVisible(true)}
                                    className="login-form-button">Restart</Button>
                        </div>
                    </Col>
                </Row>
                {
                    chargerSocketDetails?.data.map((chargerSocketDetail) => (
                        <Row style={{marginTop: 20,}}>
                            <Col md={6}
                                 style={{
                                     paddingBottom: 15,
                                     paddingRight: 15,
                                     paddingLeft: 15,
                                     marginLeft: 0,
                                     marginRight: 0
                                 }}>
                                <Card
                                    size="small"
                                    title="Charger Socket"
                                    style={{backgroundColor: "#999797", borderRadius: 12, cursor: "pointer"}}
                                    // extra={<ArrowRightOutlined style={{color: "white", fontSize: 20}}/>}
                                >
                                    <Row>
                                        <Col md={4}>
                                            <Image
                                                src={require("../../../assets/images/default_pic.PNG")}
                                                preview={false}
                                            />
                                        </Col>
                                        <Col md={8}>
                                            <Row>
                                                <Col md={6}>
                                                    <span style={{display: "block", marginBottom: 10}}>
                                                    {
                                                        chargerSocketDetail.status.status === "UNDER_MAINTENANCE" && (
                                                            <span style={{
                                                                display: "inline-block",
                                                                paddingLeft: 5,
                                                                paddingRight: 7,
                                                                backgroundColor: "#D2F019"
                                                            }}>&nbsp;&nbsp;</span>
                                                        )
                                                    }
                                                        {
                                                            chargerSocketDetail.status.status === "OCCUPIED" && (
                                                                <span style={{
                                                                    display: "inline-block",
                                                                    paddingLeft: 5,
                                                                    paddingRight: 7,
                                                                    backgroundColor: "#DE4545"
                                                                }}>&nbsp;&nbsp;</span>
                                                            )
                                                        }
                                                        {
                                                            chargerSocketDetail.status.status === "UNOCCUPIED" && (
                                                                <span style={{
                                                                    display: "inline-block",
                                                                    paddingLeft: 5,
                                                                    paddingRight: 7,
                                                                    backgroundColor: "#47B02D"
                                                                }}>&nbsp;&nbsp;</span>
                                                            )
                                                        }
                                                        {
                                                            chargerSocketDetail.status.status === "RESERVED" && (
                                                                <span style={{
                                                                    display: "inline-block",
                                                                    paddingLeft: 5,
                                                                    paddingRight: 7,
                                                                    backgroundColor: "#E98F26"
                                                                }}>&nbsp;&nbsp;</span>
                                                            )
                                                        }
                                                        <span
                                                            style={{
                                                                display: "inline-block",
                                                                marginLeft: 5
                                                            }}>{chargerSocketDetail.status.status}</span>
                                                    </span>
                                                    <span style={{display: "block", marginBottom: 10}}>
                                                        <span style={{
                                                            display: "inline-block",
                                                            marginLeft: 5
                                                        }}>Type: {chargerSocketDetail.type.name}</span>
                                                    </span>
                                                    <span style={{display: "block", marginBottom: 10}}>
                                                        <span style={{display: "inline-block", marginLeft: 5}}>Electric current type: {chargerSocketDetail.currentType}</span>
                                                    </span>
                                                    <span style={{display: "block", marginBottom: 10}}>
                                                        <span style={{display: "inline-block", marginLeft: 5}}>Power rating: {chargerSocketDetail.powerRating} kW</span>
                                                    </span>
                                                </Col>
                                                <Col md={6}>
                                                    <Row style={{marginTop: 20,}}>
                                                        <Col xs={4} style={{textAlign: "center"}}>
                                                            <div
                                                                onClick={() => onStartSocket(chargerSocketDetail.id.id)}>
                                                                <span style={{display: "block"}}>
                                                                <Image
                                                                    src={require("../../../assets/images/startstop.png")}
                                                                    style={{height: 50, width: 50}}
                                                                    preview={false}
                                                                />
                                                            </span>
                                                                <span style={{
                                                                    color: "white",
                                                                    fontWeight: "500",
                                                                    fontSize: "1vw"
                                                                }}>Start</span>
                                                            </div>
                                                        </Col>
                                                        <Col xs={4} style={{textAlign: "center"}}>
                                                            <div
                                                                onClick={() => onStopSocket(chargerSocketDetail.id.id)}>
                                                                <span style={{display: "block"}}>
                                                                <Image
                                                                    src={require("../../../assets/images/startstop.png")}
                                                                    style={{height: 50, width: 50}}
                                                                    preview={false}
                                                                />
                                                            </span>
                                                                <span style={{
                                                                    color: "white",
                                                                    fontWeight: "500",
                                                                    fontSize: "1vw"
                                                                }}>Stop</span>
                                                            </div>
                                                        </Col>
                                                        <Col xs={4} style={{textAlign: "center"}}>
                                                            <span style={{display: "block"}}>
                                                                <Image
                                                                    src={require("../../../assets/images/unlockcable.png")}
                                                                    style={{height: 50, width: 50}}
                                                                    preview={false}
                                                                />
                                                            </span>
                                                            <span style={{
                                                                color: "white",
                                                                fontWeight: "500",
                                                                fontSize: "1vw"
                                                            }}>Unlock cable</span>
                                                        </Col>
                                                        {
                                                            chargerSocketDetail.carBarrier && (
                                                                <>
                                                                    <Col xs={4} style={{textAlign: "center"}}>
                                                                        <div
                                                                            onClick={() => onUpBarrier(chargerSocketDetail.id.id)}>
                                                                <span style={{display: "block"}}>
                                                                <Image
                                                                    src={require("../../../assets/images/startstop.png")}
                                                                    style={{height: 50, width: 50}}
                                                                    preview={false}
                                                                />
                                                            </span>
                                                                            <span style={{
                                                                                color: "white",
                                                                                fontWeight: "500",
                                                                                fontSize: "1vw"
                                                                            }}>Up Barrier</span>
                                                                        </div>
                                                                    </Col>
                                                                    <Col xs={4} style={{textAlign: "center"}}>
                                                                        <div
                                                                            onClick={() => onDownBarrier(chargerSocketDetail.id.id)}>
                                                                <span style={{display: "block"}}>
                                                                <Image
                                                                    src={require("../../../assets/images/startstop.png")}
                                                                    style={{height: 50, width: 50}}
                                                                    preview={false}
                                                                />
                                                            </span>
                                                                            <span style={{
                                                                                color: "white",
                                                                                fontWeight: "500",
                                                                                fontSize: "1vw"
                                                                            }}>Down Barrier</span>
                                                                        </div>
                                                                    </Col>
                                                                </>
                                                            )
                                                        }
                                                    </Row>
                                                </Col>
                                            </Row>

                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    ))
                }
            </Container>

            <Modal title="Restart Charger" visible={isRestartModalVisible} onOk={retestChargers}
                   onCancel={() => setIsRestartModalVisible(false)}>
                <p>Are you sure want to restart this charger?</p>
            </Modal>
        </div>
    )
}

export default SingleChargePoint
